@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/modal';
@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/variables';
@import '../../../styles/variables';

// Use the arduino modal
.arduino-modal {
  width: 100%;

  &.primary {
    @include modal($white, $white);

    transition: none;

    .modal-body {
      border-top: none;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 27px;
      text-align: center;
      position: relative;
      border: 1px solid #dae3e3;
      border-radius: 6px;
      transition: none;
      padding: 65px;

      .modal-close {
        color: $asbestos;
        position: absolute;
        right: 26px;
        top: 20px;

        &:hover {
          color: $silver;
        }
      }
    }

    .modal-backdrop {
      transition: none;
    }

    &.open {
      .modal-backdrop {
        background: rgba(74, 74, 74, 0.9);
      }

      .modal-body {
        transition: none;
        padding: 65px;
      }
    }
  }
}

.modal-close {
  $button-size: 24px;

  cursor: pointer;

  @include size($button-size);

  min-height: $button-size;
  min-width: $button-size;
  opacity: 1;
  position: relative;

  &:hover {
    opacity: 0.5;
  }

  &::before,
  &::after {
    background-color: #4e5b61;
    content: ' ';
    height: $button-size;
    left: 15px;
    position: absolute;
    width: 3px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
