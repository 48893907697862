@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spinner';
@import '../../../styles/variables';

.page-loader {
  @include spinner($carrot, $white, 100px);

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
