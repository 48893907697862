@import '~arduino-sass/src/variables';

// Typography
$title-size: 36px;
$title-size-md: 26px;
$title-size-sm: 22px;
$default-size: 20px;
// Header
$header-height: 70px;

// Included fonts
$font-sans: 'Open Sans', sans-serif;

$breakpoint-xxl: 1441;
$breakpoint-xl: 1400;
$breakpoint-lg: 1200;
$breakpoint-md: 1023;
$breakpoint-sm: 767;
$breakpoint-xm: 374;
