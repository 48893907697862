@import '../../styles/variables';
@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/spinner';

.cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}

.button {
  padding: 10px 35px;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1.15px;
  font-size: 18px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;

  @include breakpoint($breakpoint-xl) {
    padding: 13px 30px;
  }

  @include breakpoint($breakpoint-sm) {
    font-size: 16px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.default-button {
  @extend .button;

  color: $white;
  background-color: $teal2;
}

.hero {
  min-height: 80vh;
  background-color: $carrot;
  display: flex;
  align-items: center;
  margin-bottom: 100px;

  .main-container {
    @include breakpoint($breakpoint-xxl, 'min') {
      max-width: 1600px;
    }
  }

  @include breakpoint($breakpoint-lg) {
    min-height: 90vh;
    margin-bottom: 50px;
  }

  @include breakpoint($breakpoint-md) {
    min-height: 60vh;
    margin-bottom: 50px;
  }

  @include breakpoint($breakpoint-sm) {
    min-height: 500px;
  }

  &__content {
    max-width: 510px;

    @include breakpoint($breakpoint-xl) {
      max-width: 430px;
    }

    @include breakpoint($breakpoint-lg) {
      padding: 0 50px;
      max-width: 65%;
    }

    @include breakpoint($breakpoint-md) {
      max-width: 70%;
    }

    @include breakpoint($breakpoint-sm) {
      max-width: 100%;
      padding: 0 20px;
    }
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 30px;
    text-transform: uppercase;
    line-height: 1.3;

    @include breakpoint($breakpoint-xl) {
      font-size: 40px;
    }

    @include breakpoint($breakpoint-md) {
      font-size: 36px;
    }

    @include breakpoint($breakpoint-sm) {
      font-size: 22px;
    }
  }

  &__text {
    margin-bottom: 55px;
    font-size: 30px;

    @include breakpoint($breakpoint-xl) {
      font-size: 26px;
      margin-bottom: 75px;
    }

    @include breakpoint($breakpoint-md) {
      font-size: 24px;
    }

    @include breakpoint($breakpoint-sm) {
      font-size: 14px;
    }
  }
}

.offers {
  &__links {
    @include breakpoint($breakpoint-sm) {
      text-align: center;
    }
  }

  &__image {
    text-align: center;
    margin-bottom: 60px;

    img {
      max-width: 60%;

      @include breakpoint($breakpoint-sm) {
        max-width: 80%;
      }
    }
  }

  &__content {
    max-width: 680px;
    width: 100%;
    margin: 0 auto 125px;
    text-align: center;
  }

  &__title {
    font-size: $title-size;
    margin-bottom: 38px;
    text-transform: uppercase;
    font-weight: 700;

    @include breakpoint($breakpoint-lg) {
      font-size: $title-size-md;
      margin-bottom: 30px;
    }

    @include breakpoint($breakpoint-sm) {
      font-size: $title-size-sm;
    }
  }

  &__description {
    margin-bottom: 60px;
    font-size: 20px;

    @include breakpoint($breakpoint-md) {
      font-size: 16px;
    }
  }

  &__list {
    max-width: 1200px;
    margin: 0 auto 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    border: 1px solid #c9d1d2;
    box-sizing: border-box;
    border-radius: 6px;
    width: 47.5%;
    padding: 15px;

    @include breakpoint($breakpoint-sm) {
      width: 100%;
      margin-bottom: 40px;
      padding: 10px;
    }

    &--wide {
      width: 100%;

      @include breakpoint($breakpoint-md, 'min') {
        flex-direction: row;

        .offers__item-image {
          width: 55%;
        }

        .offers__item-content {
          flex: 1;
          justify-content: center;
          display: flex;
          flex-direction: column;
        }
      }
    }

    &-image {
      @include breakpoint($breakpoint-md) {
        width: 100%;
        text-align: center;
      }
    }

    &-content {
      padding: 30px 40px;

      @include breakpoint($breakpoint-lg) {
        padding: 30px;
      }

      @include breakpoint($breakpoint-md) {
        padding: 30px 0;
      }
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 25px;
      text-transform: uppercase;

      @include breakpoint($breakpoint-lg) {
        font-size: $title-size-md;
      }

      @include breakpoint($breakpoint-sm) {
        font-size: $title-size-sm;
      }

      p {
        margin: 0;
      }
    }

    &-text {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 26px;

      @include breakpoint($breakpoint-lg) {
        font-size: $title-size-sm;
      }

      @include breakpoint($breakpoint-sm) {
        font-size: 16px;
      }
    }

    &-link-out {
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      font-size: 16px;
      display: inline-block;

      &-icon {
        width: 13px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(150%, -50%);
      }

      &--wrapper {
        margin-bottom: 30px;
      }
    }

    &-link {
      display: inline-block;
      text-align: center;
      color: $white;
      background-color: $carrot;
      cursor: pointer;
      position: relative;

      @extend .button;

      .button-loader {
        @include spinner($white, $carrot, 30px);

        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }

      &.disabled {
        background-color: $smoke;
      }
    }
  }
}

.certification {
  background-color: $carrot;

  @include breakpoint($breakpoint-sm) {
    background-position: 50% 0;
    margin-bottom: 50px;
  }

  .main-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 70vh;

    @include breakpoint($breakpoint-md) {
      min-height: 500px;
    }
  }

  &__content {
    max-width: 600px;

    @include breakpoint($breakpoint-md) {
      max-width: 100%;
      padding: 0 30px;
    }
  }

  &__title {
    font-size: $title-size;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1.3;

    @include breakpoint($breakpoint-lg) {
      font-size: $title-size-md;
    }

    @include breakpoint($breakpoint-sm) {
      font-size: $title-size-sm;
    }
  }

  &__text {
    margin-bottom: 35px;
    font-size: $title-size-md;

    @include breakpoint($breakpoint-lg) {
      font-size: 25px;
    }

    @include breakpoint($breakpoint-sm) {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__link {
    color: $white;
    background-color: $teal2;

    @extend .button;
  }
}

.menu-icon {
  width: 18px;
  height: 18px;

  &__block {
    @include breakpoint($breakpoint-md, 'min') {
      display: none;
    }
  }
}

.get-started {
  background-color: #bcaf9f;

  @include breakpoint($breakpoint-sm) {
    background-position: 50% 0;
    margin-bottom: 50px;
  }

  .main-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 585px;

    @include breakpoint($breakpoint-lg) {
      min-height: 760px;
    }

    @include breakpoint($breakpoint-sm) {
      min-height: 500px;
      padding-top: 100px;
      align-items: flex-start;
    }

    @include breakpoint($breakpoint-xm) {
      padding-top: 70px;
    }
  }

  &__content {
    max-width: 450px;
    margin-right: 200px;

    @include breakpoint($breakpoint-lg) {
      padding: 0 30px;
      max-width: 400px;
      margin-right: 150px;
    }

    @include breakpoint($breakpoint-md) {
      max-width: 500px;
      margin-right: 50px;
    }

    @include breakpoint($breakpoint-sm) {
      margin-right: 0;
      max-width: 100%;
    }
  }

  &__title {
    font-size: $title-size;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1;

    @include breakpoint($breakpoint-sm) {
      font-size: $title-size-sm;
      letter-spacing: 0.9;
    }
  }

  &__text {
    margin-bottom: 35px;
    font-size: 24px;

    @include breakpoint($breakpoint-sm) {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 60px;
    }
  }

  &__link {
    color: $white;
    background-color: $carrot;

    @extend .button;
  }
}

.modal {
  &__title {
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 50px;

    &--message {
      margin-bottom: 15px;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }

  &__text {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.5px;
    margin-bottom: 45px;

    &::first-letter {
      text-transform: uppercase;
    }

    &--message {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $white;
    background-color: $carrot;

    @extend .button;
  }

  &__row {
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
  }

  &__input {
    background: #ecf1f1 !important;
    border-radius: 6px;
    border: none;
    font-size: 24px;
    line-height: 1;
    color: #434f54;
    padding: 15px 35px;
    max-width: 300px;
    text-align: center;
    letter-spacing: 1.14px;
    margin-right: 10px;
  }

  &__button {
    border: none;
    color: $carrot;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;

    &--copied {
      color: #1da086;
    }
  }
}
